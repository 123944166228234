/**
 * This page collects the users first name, last name, and allows them to
 * include their invite code if they were invited.
 *
 * The invite code may be populated by the previous page via another users
 * invite link
 *
 */
import React, { useState, useReducer } from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import hasParams from '../components/has_params';
import {
  Checkbox,
  Box,
  Button,
  TextField,
  Container,
  CssBaseline,
  Divider,
  Typography,
  makeStyles,
  FormControl,
  CircularProgress,
  FormHelperText,
  ListSubheader,
  withStyles,
  InputBase,
  Grid,
  FormLabel, RadioGroup, FormControlLabel, Radio, Select, Input, Chip, MenuItem, Paper,
  Stepper,
  Step,
  StepLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core"
import { navigate } from "@reach/router"
import axios from 'axios';
import {
  API_ADDRESS,
  TOPIC_TYPE,
  TOPIC_VISIBILITY,
  createEnum,
  TOPIC_MEDICINE_ID,
  LINKEDIN_REGEX, GREENWOODX_NETWORK_KEY,
} from "../lib/constants"
import {setAuthToken, isAuthenticated} from "../lib/auth";

const FIRST_NAME = 'first-name';
const LAST_NAME = 'last-name';
const EMAIL = 'email';
const INVITE_CODE = 'invite-code';
const GENDER = 'gender';
const RACE = 'race';
const USER_TYPE = 'user-type';
const KNOWN_TOPICS = 'known-topics';
const OTHER_KNOWN_TOPICS = 'other-known-topics';
const INTEREST_TOPICS = 'interest-topics';
const OTHER_INTEREST_TOPICS = 'other-interests-topics';
const WILL_MENTOR = 'will-mentor';
const MEDICINE = 'medicine';
const LINKEDIN = 'linkedIn'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const FormControlProps = {
  'component':'fieldset',
  'margin':'normal',
  'fullWidth': true
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  topicSectionBox: {
    margin: theme.spacing(2, 1)
  },
  expansionDetailOverride: {
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(.25, .25)
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  fullWidth: {
    width: '100%'
  },
  stepButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    width: '100%'
  }
}));

// Set by the call to the api.
const TOPIC_LABEL_MAP = {};
function buildTopicLabelMap(topics) {
  topics.forEach((topLevel) => {
    topLevel['SubTopics'].forEach((main) => {
      TOPIC_LABEL_MAP[main['id']] = main['Label'];
    })
  });
}

const TOPIC_MENU_ITEMS = [];
function getTopicMenuItems(topics) {
  if (TOPIC_MENU_ITEMS.length != 0) {
    return TOPIC_MENU_ITEMS;
  }
  // This function handles 2 level of topics (TOPLEVEL and MAIN).
  topics.forEach((topLevel) => {
    TOPIC_MENU_ITEMS.push(
      <ListSubheader key={topLevel['id']} disableSticky>{topLevel['Label']}</ListSubheader>
    );
    topLevel['SubTopics'].forEach((main) => {
      TOPIC_MENU_ITEMS.push(
        <MenuItem key={main['id']} value={main['id']} >
          {main['Label']}
        </MenuItem>
      );
    });
  });
  return TOPIC_MENU_ITEMS;
}

const GENDER_MAP = {
  1: 'Female',
  2: 'Male',
  3: 'Genderqueer / Non-Binary',
  4: 'Prefer not to say'
};

const RACE_MAP = {
  1: 'Black or African Descent',
  2: 'Hispanic or Latinx',
  3: 'White',
  4: 'Asian',
  5: 'Indian',
  6: 'Native Hawaiian or Other Pacific Islander',
  7: 'American Indian or Alaska Native',
  8: '2 or More Races',
  9: 'Prefer not to say'
}

// For the question - Are you a student?
const USER_TYPE_MAP = {
  2: 'Yes',
  1: 'No'
}

const MEDICINE_MAP = {
  2: 'Yes',
  1: 'No'
}

const STEPPER_PAGE = createEnum(['BASIC_INFO', 'KNOWN', 'INTERESTS', 'FINISH'], 0);

function getMenuItemsFromMap(map) {
  let elements = [];
  Object.keys(map).forEach((key) => {
    elements.push(
      <MenuItem key={Number(key)} value={Number(key)}>
        {map[key]}
      </MenuItem>
    );
  });
  return elements;
}

const getStepperSteps = (userType) => {
  // For Professionals.
  if (userType == 1) {
    return ['Basic Info', 'Your Knowledge', 'Interests'];
  }
  return ['Basic Info', 'Interests'];
};

const Registration = ({location, params}) => {
  const classes = useStyles();

  const topicChipReducer = (state, data) => {
    let new_value = true;
    if (state && state[data['id']]) {
      const newState = Object.assign({}, state);
      delete newState[data['id']];
      return newState;
    }
    return {...state, [data['id']]:new_value};
  }
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  // Topics is an array of {id: Label} representing a single topic.
  const [topics, setTopics] = useState([]);
  const [knownTopicState, dispatchKnownTopicToggle] = useReducer(topicChipReducer, {})
  const [knownTopicStateError, setKnownTopicStateError] = useState(false);
  const [otherKnownTopics, setOtherKnownTopics] = useState("");
  const [interestTopicState, dispatchInterestTopicToggle] = useReducer(topicChipReducer, {})
  const [interestTopicStateError, setInterestTopicStateError] = useState(false);
  const [otherInterestTopics, setOtherInterestTopics] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [userType, setUserType] = useState(0);
  const [userTypeError, setUserTypeError] = useState(false);
  const [gender, setGender] = useState(0);
  const [genderError, setGenderError] = useState(false);
  const [race, setRace] = useState(0);
  const [raceError, setRaceError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [willMentor, setWillMentor] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [inMedicine, setInMedicine] = useState(false);
  const [linkedIn, setLinkedIn] = useState('https://www.linkedin.com/in/');
  const [linkedinError, setLinkedinError] = useState(false);


  // Fetch data from the server. Setting deps to [] forces this to only run
  // once.
  React.useEffect( () => {
    // QUICK HACK FOR BYPASSING ON DEV
    if (true) {

      // Redirect to the app if this user is already authorized.
      if (isAuthenticated()) {
        navigate('/app');
        return;
      }
      // Redirect to home page if there is no registration code.
      if (!('code' in params)) {
        navigate( "/" );
        return;
      }

      // Redirect to home page if the registration code isn't valid.
      axios.get(`${API_ADDRESS}/user/registration-state?code=${params['code']}`)
        .then((response) => {
          if (response.data.error){
            navigate('/', {
              state: {
                snackbarMessage:response.data.error_message
              }
            });
            return;
          }
          if (response.data.prospect) {
            const user = response.data.prospect;
            if (user.FirstName) {
              setFirstName(user.FirstName);
            }
            if (user.LastName) {
              setLastName(user.LastName);
            }
            if (user.Email) {
              setEmail(user.Email);
            }
          }
        });
    }

    axios.get(`${API_ADDRESS}/topics/?networkKey=${GREENWOODX_NETWORK_KEY}`)
      .then((response) => {
        if (response.data.error) {
          return;
        }
        processTopics(response.data.topics);
      });
  }, []);

  const processTopics = (topics) => {
    setTopics(topics);

    // Create state for each topic chip.
    const data = {};
    // This function handles 2 level of topics (TOPLEVEL and MAIN).
    topics.forEach((topLevel) => {
     topLevel['SubTopics'].forEach((main) => {
       data[main['id']] = false;
     });
    });
    // setTopicData(data);
  };
  const getTopicChips = (topicState, dispatchTopicToggle) => {
    let containing_elements = [];
    topics.forEach((topLevel) => {
      // Either only show the medschool topics or don't show them at all
      // depending on if the person is in the medicine field or not.
      if (inMedicine && topLevel['id'] != TOPIC_MEDICINE_ID) {
        return;
      } else if (!inMedicine && topLevel['id'] == TOPIC_MEDICINE_ID) {
        return;
      }

      let elements = [];
      topLevel['SubTopics'].forEach((main) => {
        let variant = 'outlined';
        if (topicState && topicState[main["id"]])
          variant = "default";
        elements.push(
          <Chip
              color="primary"
              key={main["id"]}
              label={main["Label"]}
              className={classes.chip}
              onClick={(()=>dispatchTopicToggle(main))}
              variant={variant}
          />
        );
      });
      containing_elements.push(
          <ExpansionPanel key={topLevel['Label']} expanded={expanded === topLevel['id']} onChange={handleExpandedChange(topLevel['id'])}>
            <ExpansionPanelSummary>
              <Typography component="h6" variant="subtitle2">
                {topLevel['Label']}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionDetailOverride}>
              {elements}
            </ExpansionPanelDetails>
          </ExpansionPanel>
      );
    })
    return (
      <Box className={classes.topicSectionBox}>
        {containing_elements}
      </Box>

      );
    // return containing_elements;
  }


  // Network States.

  // Form  States and handlers.
  // TextField errors are handled by the browser.
  const handleTextField = event => {
    switch(event.target.name) {
      case FIRST_NAME:
        setFirstName(event.target.value);
        break;
      case LAST_NAME:
        setLastName(event.target.value);
        break;
      case EMAIL:
        setEmail(event.target.value);
        break;
      case INVITE_CODE:
        setInviteCode(event.target.value);
        break;
      case LINKEDIN:
        setLinkedIn(event.target.value);
        break;
      case OTHER_KNOWN_TOPICS:
        setOtherKnownTopics(event.target.value);
        break;
      case OTHER_INTEREST_TOPICS:
        setOtherInterestTopics(event.target.value);
        break;
    }
  }


  const handleSelectChange = event => {
    const value = event.target.value;
    switch(event.target.name) {
      case GENDER:
        setGender(value);
        setGenderError(hasFieldError(value));
        break;
      case RACE:
        setRace(value);
        setRaceError(hasFieldError(value));
        break;
      case USER_TYPE:
        setUserType(value);
        setUserTypeError(hasFieldError(value));
        break;
      case MEDICINE:
        if (value == 2){
          setInMedicine(true);
          setUserType(1);
        } else {
          setInMedicine(false);
        }
        break;
    }
  }

  const handleCheckbox = event => {
    switch(event.target.name) {
      case WILL_MENTOR:
        setWillMentor(event.target.checked);
        break;
    }
  }

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const hasFieldError = (fieldValue) => (fieldValue == 0);
  const validationMapIsValid = (validationMap) => {
    let isValid = true;
    for (const key in validationMap) {
      let hasError;
      // If a validation function is supplied use that, if not use the default.
      if (validationMap[key].length == 3) {
        hasError = validationMap[key][2](validationMap[key][0]);
      } else {
        hasError = hasFieldError(validationMap[key][0]);
      }
      validationMap[key][1](hasError)
      if (isValid && hasError) {
        isValid = false;
      }
    }
    return isValid;
  }

  const submitForm = () => {
    let known_topics = Object.keys(knownTopicState).map((key) => (Number(key)));
    let interest_topics = Object.keys(interestTopicState).map((key) => (Number(key)));
    // Make request.
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      url_code: params['code'],
      invite_code: inviteCode,
      race: Number(race),
      gender: Number(gender),
      type: userType,
      will_mentor: willMentor,
      in_medicine: inMedicine,
      linked_in: linkedIn
    };
    if (known_topics.length) {
      data['known_topics'] = known_topics;
    }
    if (otherKnownTopics.length) {
      data['other_known_topics'] = otherKnownTopics;
    }
    if (interest_topics.length) {
      data['interest_topics'] = interest_topics;
    }
    if (otherInterestTopics.length) {
      data['other_interest_topics'] = otherInterestTopics;
    }

    setFormSubmitLoading(true);
    axios.post(`${API_ADDRESS}/user/register`, data)
      .then((response) => {
        if (response.data.error) {
          if (response.data.error_message) {
            alert(response.data.error_message);
          }
          setFormSubmitLoading(false);
          return
        }
        // Save the access token to localStorage on successful account
        // creation.
        setAuthToken(response.data.token);
        navigate('/app');
      })
      .catch((error) => {
        alert("Sorry but that was an unknown error. Send an email to grnwdx@gmail.com with the following message: "+error);
        setFormSubmitLoading(false);
      });
  }

  const handleStepBack = () => {
    if (activeStep != 0 ) {
      let newActiveStep = activeStep-1;
      setActiveStep(activeStep - 1);
    }
  }

  const isKnownTopicStep = (stepIndex) => (stepIndex === 1 && userType === 1);
  const isInterestStep = (stepIndex) => ((stepIndex === 1 && userType === 2) || (stepIndex === 2));

  const linkedInHasError = (url) => {
    if (inMedicine) return false;
    return !LINKEDIN_REGEX.test(url);
  }

  const handleStepNext = () => {
    let validationMap = {};
    if (activeStep === 0 ) { // Basic Info
      validationMap[FIRST_NAME] = [firstName, setFirstNameError];
      validationMap[LAST_NAME] = [lastName, setLastNameError];
      validationMap[EMAIL] = [email, setEmailError];
      validationMap[GENDER] = [gender, setGenderError];
      validationMap[RACE] = [race, setRaceError];
      validationMap[USER_TYPE] = [userType, setUserTypeError];
      validationMap[LINKEDIN] = [linkedIn, setLinkedinError, linkedInHasError];
    } else if(isKnownTopicStep(activeStep)) { // Known Topics
      const validating = Object.keys(knownTopicState).length || otherKnownTopics.length > 5;
      validationMap[KNOWN_TOPICS] = [validating, setKnownTopicStateError];
    } else if(isInterestStep(activeStep)) {
      const validating = Object.keys(interestTopicState).length || otherInterestTopics.length > 5;
      validationMap[INTEREST_TOPICS] = [validating, setInterestTopicStateError];
    }
    let isValid = validationMapIsValid(validationMap);
    if (!isValid) return false;

    if (activeStep === getStepperSteps(userType).length - 1) {
      submitForm();
    } else {
      if (isInterestStep(activeStep+1)) {
        window.scroll(0, 0);
        alert(`Next, select some things that you're interested in connecting with others about.`);
      }
      setActiveStep(activeStep + 1);
    }
    setExpanded(false);
    if (inMedicine) {
      setExpanded(TOPIC_MEDICINE_ID)
    }
  }

  const getBasicInfoStep = () => {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Basic Info
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              id={FIRST_NAME}
              name={FIRST_NAME}
              label="First Name"
              variant="outlined"
              fullWidth
              required
              value={firstName}
              onChange={handleTextField}
              margin="dense"
              error={firstNameError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={LAST_NAME}
              name={LAST_NAME}
              label="Last Name"
              variant="outlined"
              placeholder="Last Name"
              fullWidth
              required
              value={lastName}
              onChange={handleTextField}
              margin="dense"
              error={lastNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id={EMAIL}
              name={EMAIL}
              variant="outlined"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={handleTextField}
              required
              margin="dense"
              error={emailError}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              {...FormControlProps}
              error={genderError}
            >
              <FormLabel component="legend">Gender</FormLabel>
              <Select
                id={GENDER}
                name={GENDER}
                value={gender}
                onChange={handleSelectChange}
                required
                input={<BootstrapInput />}

              >
                {getMenuItemsFromMap(GENDER_MAP)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              {...FormControlProps}
              error={raceError}
            >
              <FormLabel component="legend">Race / Ethnicity</FormLabel>
              <Select
                id={RACE}
                name={RACE}
                value={race}
                onChange={handleSelectChange}
                required
                input={<BootstrapInput />}
              >
                {getMenuItemsFromMap(RACE_MAP)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              {...FormControlProps}
            >
              <FormLabel component="legend">Are in or pursuing a  career in medicine.</FormLabel>
              <Select
                id={MEDICINE}
                name={MEDICINE}
                value={inMedicine?2:1}
                onChange={handleSelectChange}
                required
                displayEmpty
                input={<BootstrapInput />}
              >
                {getMenuItemsFromMap(MEDICINE_MAP)}
              </Select>
            </FormControl>
          </Grid>
          {!inMedicine &&
            <Grid item xs={12}>
              <FormControl
                {...FormControlProps}
                error={userTypeError}
              >
                <FormLabel component="legend">Are you a student?</FormLabel>
                <Select
                  id={USER_TYPE}
                  name={USER_TYPE}
                  value={userType}
                  onChange={handleSelectChange}
                  required
                  displayEmpty
                  input={<BootstrapInput/>}
                >
                  {getMenuItemsFromMap(USER_TYPE_MAP)}
                </Select>
              </FormControl>
            </Grid>
          }
          {!inMedicine &&
          <Grid item xs={12}>
            <FormLabel component="legend">What's your LinkedIn URL?</FormLabel>
            <TextField
              id={LINKEDIN}
              name={LINKEDIN}
              variant="outlined"
              label="LinkedIn URL"
              fullWidth
              value={linkedIn}
              onChange={handleTextField}
              required
              margin="dense"
              error={linkedinError}
            />
          </Grid>
          }
        </Grid>
      </React.Fragment>
    );
  }

  const normalOrErrorColor = (error) => (error?"error":"textPrimary");
  const getKnownTopicsStep = () => {
    return (
      <React.Fragment>
        <Typography variant="h6" color={normalOrErrorColor(knownTopicStateError)}>
          Your Knowledge
        </Typography>
        <Typography variant="body2" color={normalOrErrorColor(knownTopicStateError)}>
          Pick the topics below that you're knowledgeable in.
        </Typography>
        <Grid item xs={12}>
          {getTopicChips(knownTopicState, dispatchKnownTopicToggle)}
          <Divider variant="middle" />
          <Box key={OTHER_KNOWN_TOPICS} className={classes.topicSectionBox} >
            <Typography variant="subtitle1">
              Did we miss a topic?
            </Typography>
            <Typography variant="body2">
              Please add it below.
            </Typography>
            <TextField
              id={OTHER_KNOWN_TOPICS}
              name={OTHER_KNOWN_TOPICS}
              label="Other topics"
              variant="outlined"
              placeholder="Separate your list with a comma."
              fullWidth
              value={otherKnownTopics}
              onChange={handleTextField}
              margin="dense"
              error={knownTopicStateError}
            />
          </Box>
          {!inMedicine &&
            <Box key={WILL_MENTOR} className={classes.topicSectionBox}>
              <FormControlLabel
                checked={willMentor}
                onChange={handleCheckbox}
                name={WILL_MENTOR}
                control={
                  <Checkbox
                    color="primary"
                  />
                }
                label="I'm able to speak with students about these topics."
              />
            </Box>
          }
        </Grid>
      </React.Fragment>
    );
  }

  const getInterestTopicsStep = () => {
    return (
      <React.Fragment>
        <Typography variant="h6" color={normalOrErrorColor(interestTopicStateError)}>
          Interest
        </Typography>
        <Typography variant="body2" color={normalOrErrorColor(interestTopicStateError)}>
          Which topics below are you interested in speaking to someone about?
        </Typography>
        <Grid item xs={12}>
          {getTopicChips(interestTopicState, dispatchInterestTopicToggle)}
          <Divider variant="middle" />
          <Box key={OTHER_KNOWN_TOPICS} className={classes.topicSectionBox} >
            <Typography variant="subtitle1">
              Did we miss an interest?
            </Typography>
            <Typography variant="body2">
              Please add it below.
            </Typography>
            <TextField
              id={OTHER_INTEREST_TOPICS}
              name={OTHER_INTEREST_TOPICS}
              label="Other interests"
              variant="outlined"
              placeholder="Separate your list with a comma."
              fullWidth
              value={otherInterestTopics}
              onChange={handleTextField}
              margin="dense"
              error={interestTopicStateError}
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  }


  const getStepperPage = (stepIndex) => {
    if (stepIndex === 0) {
      return getBasicInfoStep();
    } else if (isKnownTopicStep(stepIndex)) {
      return getKnownTopicsStep();
    } else if (isInterestStep(stepIndex)) {
      return getInterestTopicsStep();
    }
  }

  return (
    <Layout>
      <SEO title="Registration"/>
      <Container maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper} elevation={1} >
          <Typography component="h1" variant="h5">
            Registration
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel className={classes.fullWidth}>
            {getStepperSteps(userType).map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box>
            {getStepperPage(activeStep)}
          </Box>
          <Box className={classes.stepButtons}>
            {activeStep !== 0 && (
              <Button onClick={handleStepBack} className={classes.button}>
                Back
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleStepNext}
              className={classes.button}
            >
              {activeStep === getStepperSteps(userType).length -1 ?
                (formSubmitLoading ? <CircularProgress size={24} /> : 'Register') : 'Continue'}
            </Button>
          </Box>
          {activeStep === getStepperSteps(userType).length - 1 &&
            <Typography variant="caption" style={{ marginTop: '20px', color: 'grey' }}>
              By registering, you agree to Greenwoodx's <a href="https://bit.ly/greenwoodx_terms_of_service" target="_blank">Terms of Service</a>, <a href="https://bit.ly/greenwoodx_privacy_policy" target="_blank">Privacy Policy</a>
            </Typography>
          }
        </Paper>
        <Grid item xs={12}>
        </Grid>
      </Container>
    </Layout>
  );
}

export default hasParams(Registration)
